<template>
  <div 
    class="wrap-event-card" 
    :class="{ 'active-yellow': index === focusEventIndex && !checkUrgent(),
    'active-red': index === focusEventIndex && checkUrgent() }" 
    @click="setFocusEvent()" @mouseover="isShowAction = true" @mouseleave="isShowAction = false"
  >
    <div class="event-tab" :class="{
      focus: index === focusEventIndex,
      'urgent-tab': checkUrgent(), 
      'matched-tab': event.matched === 1 }"></div>
    <div class="container-img">
      <div class="photo" @click="showEventSnapShot">
        <img v-lazy="event.photoUrl">
        <div class="hover-icon">
          <img src="@/assets/icons/zoom-in.svg" alt="">
        </div>   
      </div>
      <div class="snapshot">
        <img v-lazy="event.snapshotUrl">
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('history_channel')/*鏡頭*/" placement="left">
          <img v-if="event.channelId === 0" class="camera" src="@/assets/icons/camera1-m.svg" alt="">
          <img v-else-if="event.channelId === 1" class="camera" src="@/assets/icons/camera2-m.svg" alt="">
        </el-tooltip>
      </div>
    </div>
    <div class="container-info">
      <div class="event-index">{{ (historyCurrentPage - 1) * historyPageSize + index + 1 }}</div>
      <div class="plate">
        <div v-if="event.triggered[0].classification === 3" class="car-icon">
          <img src="@/assets/icons/Car_L_yellow.svg" alt="">
        </div>
        <div v-else-if="event.triggered[0].classification === 4" class="moto-icon">
          <img src="@/assets/icons/Motor_L_yellow.svg" alt="">
        </div>
        <div v-else class="car-icon">
          <img src="@/assets/icons/CarPlate_yellow.svg" alt="">
        </div>
        <div class="plate-code">{{ event.triggered[0].content }}</div>
      </div>
      <div class="time" :class="{ focus: index === focusEventIndex }">{{ detectTime }}</div>
      <div v-if="checkUrgent()" class="info video-title">
        <img src="@/assets/icons/Camera.svg" alt="">
        {{ getDeviceTitleId(event.user.id) }}
      </div>
      <div v-else class="info video-title">
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('history_device')/*辨識設備*/" placement="left">
          <img src="@/assets/icons/Camera.svg" alt="">
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" :disabled="!isShowTooltip" effect="dark" v-delTabIndex :content="getDeviceTitleId(event.user.id)" placement="left">
          <span ref="videoTitle" @mouseover="onMouseOver('videoTitle')">{{ getDeviceTitleId(event.user.id) }}</span>
        </el-tooltip>
      </div>
      <div v-if="checkUrgent() && event.linkedUserName" class="info link">
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('link')/*勾稽*/" placement="left">
          <img src="@/assets/icons/user.svg" alt="">
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" :disabled="!isShowTooltip" effect="dark" v-delTabIndex :content="`${ event.linkedUserName } (${ event.linkedUserAccount })`" placement="left">
          <span ref="linkedUser" @mouseover="onMouseOver('linkedUser')">{{ `${ event.linkedUserName } (${ event.linkedUserAccount })` }}</span>
        </el-tooltip>
      </div>
      <div v-if="event.triggered[0].tag.length > 0" class="info tag">
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('history_tag')/*車輛標記*/" placement="left">
          <img src="@/assets/icons/tag-s.svg" alt="">
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" :disabled="!isShowTooltip" effect="dark" v-delTabIndex :content="getTag(event.triggered[0].tag)" placement="left">
          <div ref="tags" class="tags" @mousemove="onMouseOver('tags')">
            <span v-for="(tag, index) in getTagNameList(event.triggered[0].tag)" :key="index">{{ tag }}</span>
          </div>
        </el-tooltip>
      </div>
      <div v-else-if="isDemo" class="info tag">
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('history_tag')/*車輛標記*/" placement="left">
          <img src="@/assets/icons/tag-s.svg" alt="">
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" :disabled="!isShowTooltip" effect="dark" v-delTabIndex :content="getTag(event.triggered[0].tag)" placement="left">
          <div ref="tags" class="tags" @mousemove="onMouseOver('tags')">
            <span>{{ $t('no_subscription') }}</span>
          </div>
        </el-tooltip>
      </div>
      <div v-if="checkUrgent() || (searchMode === 'advanced' && codeBooks.mission && event.missionCode > 0)" class="separater"></div>
      <div v-if="checkUrgent()" class="info urgent">
        <img src="@/assets/icons/urgent-s.svg" alt="">
        {{ $t('event_chasing')/*圍捕*/ }}
      </div>
      <!-- 進階查詢時才顯示任務相關資料 -->
      <div v-if="searchMode === 'advanced' && codeBooks.mission && event.missionCode > 0" class="push-message">
        <div class="message">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('history_mission')/*任務*/" placement="left">
            <img :src="missionIcon" alt="">
          </el-tooltip>
          {{ codeBooks.mission[event.missionCode] }}<span v-if="event.missioned === 3">{{ $t('search_finish')/*完成*/ }}</span>
        </div>
        
        <div class="info push-content">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('history_push_message')/*推送訊息*/" placement="left">
            <img src="@/assets/icons/note.svg" alt="">
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" :disabled="!isShowTooltip" effect="dark" v-delTabIndex :content="event.missionNotice" placement="left">
            <span ref="missionNotice" @mouseover="onMouseOver('missionNotice')">{{ event.missionNotice }}</span>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="container-action" :class="{ 'space-between': isShowAction || index === focusEventIndex }">
      <div v-show="isShowAction || index === focusEventIndex">
        <el-tooltip popper-class="el-tooltip" effect="dark" :content="$t('history_event_map_goto')/*開啟Google map*/" placement="top">
          <div class="g-map" @click="goGoogleMap">
            <img src="@/assets/icons/google-map-icon.png" alt="">
          </div>
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" effect="dark" :content="$t('history_query_by_event')/*以事件查詢*/" placement="top">
          <div v-if="searchMode === 'basic'" class="device-search" @click="queryByEvent(event.user.id, event.detectTime)">
            <img src="@/assets/icons/device-search.svg" alt="">
          </div>
        </el-tooltip>
      </div>
      <el-tooltip popper-class="el-tooltip" effect="dark" :content="$t('search_misjudgment')/*誤判*/" placement="top">
        <div class="mis-judgment" :class="{ error: event.misjudged }" @click.stop="setEventMisjudged(event)">
          <img :src="getMisjudgedIcon(event.misjudged)" alt="">
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { apiEditBoviaLprEvent } from '@/api/index.js'
import { getMisjudgedIcon } from '@/utils/index.js'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'EventCard',
  props: ['event', 'index'],
  data() {
    return {
      // tag: '走私車,白牌車, 酒駕,毒品車,...',
      isShowTooltip: false,
      isShowAction: false,
    }
  },
  computed: {
    ...mapState(['isDemo', 'codeBooks', 'tagList']),
    ...mapState('history', ['searchMode', 'focusEventIndex', 'historyPageSize', 'historyCurrentPage']),
    ...mapGetters('account', ['getDeviceTitleId']),
    missionIcon() {
      if (this.event.missionCode === 1) 
        return require('@/assets/icons/SearchCar_S.svg')
      else if (this.event.missionCode === 2)
        return require('@/assets/icons/Ask_S.svg')
      else if (this.event.missionCode === 3) 
        return require('@/assets/icons/Arrest_S.svg') 
      else 
        return require('@/assets/icons/SearchCar_S.svg')
    },
    detectTime() {
      return formatTime(this.event.detectTime)
    },
  },
  methods: {
    ...mapMutations('history', [
      'updateHistoryEventIndex',
      'updateHistoryEventModalShow',
      'updateFocusEventIndex',
    ]),
    getMisjudgedIcon,
    checkUrgent() {
      return this.event.chasing == 1 ||
        (this.event.chasingStartTime && this.event.chasingEndTime)
        ? true
        : false
    },
    getTagNameList(arrTag) {
      return arrTag.map(item => {
        let objTag = this.tagList.find(obj => obj.id === item)
        return objTag ? objTag.name : ''
      })
    },
    getTag(arrTag) {
      let tags = ''
      arrTag.forEach((item) => {
        let idx = this.tagList.findIndex((obj) => obj.id === item)
        if (idx !== -1) {
          let seperator = tags == '' ? '' : ' / '
          tags += seperator + this.tagList[idx].name
        }
      })
      return tags
    },
    showEventSnapShot() {
      this.updateHistoryEventIndex(this.index)
      this.updateHistoryEventModalShow(true)
    },
    setFocusEvent() {
      this.updateFocusEventIndex(this.index)
    },
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaLprEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
    goGoogleMap() {
      const url = `https://www.google.com.tw/maps/search/?api=1&query=${this.event.latitude},${this.event.longitude}`
      window.open(url, '_blank')
    },
    onMouseOver(str) {
      const tag = this.$refs[str]
      const contentWidth = tag.offsetWidth
      const contentHeight = tag.offsetHeight
      const scrollWidth = tag.scrollWidth
      const scrollHeight = tag.scrollHeight
      this.isShowTooltip = contentWidth < scrollWidth || contentHeight < scrollHeight
    },
    queryByEvent(userId, detectTime) {
      // 呼叫SearchBar的searchHistoryEvents()方法，使用Event Bus  
      this.$bus.$emit('queryByEvent', {
        name: this.getDeviceTitleId(userId),
        userId: userId,
        detectTime: detectTime,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-event-card {
  background: #19223B;
  border: 1px solid #4A5C78;
  border-radius: 6px;
  width: 100%;
  padding: 12px 10px 12px 16px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
  cursor: pointer;
}

.active-yellow {
  background-color: #384c83;
  border-color: #FFC600;
  border-width: 3px 3px 3px 0;
}

.active-red {
  background-color: #384c83;
  border-color: #F94144;
  border-width: 3px 3px 3px 0;
}

.active-gray {
  background-color: #384c83;
  border-color: #4A5C78;
  border-width: 3px 3px 3px 0;
}

.event-tab {
  position: absolute;
  width: 8px;
  background: #4A5C78;
  border: #4A5C78 1px solid;
  border-radius: 6px 0 0 6px;
  top: -1px;
  left: 0;
  bottom: -1px;
  &.focus {
    top: -3px;
    bottom: -3px;
  }
}

.matched-tab {
  background: #FFC600;
  border: #FFC600 1px solid;
}

.urgent-tab {
  background: #F94144;
  border: #F94144 1px solid;
}

.photo, .snapshot {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;
  border-radius: 3px;
}

.photo {
  width: 160px;
  height: 160px;
  margin-bottom: 6px;
  cursor: pointer;
  position: relative;
  img[lazy=error] {
    width: 60%;
    height: 60%;
  }
}

.hover-icon {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 40%;
  top: 40%;
  opacity: 0;
  transition: .5s ease;
}

.hover-icon img {
  width: 100%;
  height: 100%;
}

.photo:hover img {
  opacity: 0.7;
}

.photo:hover .hover-icon {
  opacity: 1;
}

.snapshot {
  width: 160px;
  height: 80px;
  position: relative;
  
  img[lazy=error] {
    width: auto;
    height: 80%;
  }
}

.snapshot > .camera {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 28px;
  height: 28px;
}

.photo img, .snapshot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.container-info {
  width: calc(100% - 184px);
  padding: 0 6px 0 8px;
}

.event-index {
  width: 60px;
  line-height: 32px;
  font-size: 24px;
  color: #282942;
  text-align: center;
  background: #FFE99F;
  border-radius: 8px;
  margin-bottom: 8px;
}

.plate {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  column-gap: 8px;
}

.plate-code {
  font-family: Roboto;
  font-size: px2rem(24);
  font-weight: bold;
  line-height: 33px;
  color: #FFE99F;
  min-width: 80px;
  @include xxl {
    font-size: 24px;
  }
}

.g-map {
  width: 24px;
  height: 24px;
  margin-bottom: 18px;
  cursor: pointer;
}

.g-map img {
  width: 100%;
  height: 100%;
}

.car-icon img {
  display: flex;
  align-items: center;
  width: 100%;
}

.moto-icon img {
  width: 100%;
  height: 100%;
}

.time {
  display: inline-block;
  background: #4A5C78;
  border-radius: 17px;
  padding: 4px 16px 6px;
  color: #ffffff;
  font-size: px2rem(18);
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 8px; 
}

.time.focus {
  background: #282942;
}

.info {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.info img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.video-title, .link {
  width: 100%;
  font-size: px2rem(20);
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 8px;
}

.video-title span, .link span {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tags {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.tags span {
  background: #ffffff33;
  padding: 1px 12px 2px 12px;
  border-radius: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
  font-size: px2rem(20);
  white-space: nowrap;
}

.push-message {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  margin-bottom: 4px;
  font-size: px2rem(20);
  letter-spacing: 1.5px;
  color: #ffffff;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.message img {
  margin-right: 8px;
  vertical-align: bottom;
}

.push-content {
  font-size: px2rem(20);
  line-height: 24px;
  color: #ffffff;
  word-break: break-all;
}

.push-content img {
  position: relative;
  margin-right: 8px;
  top: 3px;
}

.push-content span {
  /* width: 100%; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.container-info .info.push-content {
  align-items: flex-start;
}

/* .info img {
  width: 20px;
  height: 20px;
  margin-right: 16px;
} */

.separater {
  width: 100%;
  height: 1px;
  background: #ffffff33;
  margin: 6px 0;
}

.urgent {
  left: -2px;
}

.container-action {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.space-between {
  justify-content: space-between;
}

.select {
  position: relative;
}

input[type='checkbox'] {
  appearance: none;
  background: transparent;
  margin: 0;
  /* font: inherit; */
  width: 18px;
  height: 18px;
  border: 1px solid #4A5C78;
  border-radius: 3px;
  transform: translateY(1px);
  padding-right: 10px;
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;
}
input[type='checkbox']::before {
  content: '';
  position: absolute;
  width: 7px;
  height: 12px;
  border-right: 2px solid #19223B;
  border-bottom: 2px solid #19223B;
  box-sizing: border-box;
  left: 4px;
  top: 0px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type='checkbox']:checked {
  background: #FFC600;
  border-color: #FFC600;
}

input[type='checkbox']:checked::before {
  transform: scale(1) rotate(45deg);
}

.mis-judgment {
  opacity: 0.2;
  .error {
    opacity: 1;
  }
}

.mis-judgment img {
  vertical-align: middle;
}
</style>